/** @format */

import React from "react";
import Layout from "../Components/Layout";
import "../Styles/whatweoffer.css";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import topimage from "../../src/Assets/top image.png";
import cardimage from "../../src/Assets/Ecommerce_edited.webp";

const DigitalMarketing = () => {
  const cards = [
    {
      title: "SEO",
      description:
        "Search Engine Optimization (SEO) is the process of optimizing a website’s content and structure to improve its visibility in search engine results.",
    },
    {
      title: "Email Marketing",
      description:
        "Email marketing is a digital marketing strategy that involves sending promotional messages or newsletters to a targeted audience via email.",
    },
    {
      title: "Social Media Marketing",
      description:
        "Social media marketing refers to using platforms like Facebook, Twitter, and Instagram to promote a brand or product.",
    },
    {
      title: "Content marketing",
      description:
        "Content marketing is a strategic marketing approach that involves creating and sharing valuable, relevant, and consistent content to attract and retain a target audience. ",
    },
    {
      title: "PPC",
      description:
        "PPC (Pay-Per-Click) marketing is a type of digital advertising where advertisers pay a fee each time one of their ads is clicked. It is a way of buying traffic to a website .",
    },
    {
      title: "Social media optimization",
      description:
        "Social media optimization (SMO) is the process of optimizing and enhancing your social media presence to increase brand visibility, drive engagement, and attract a larger audience.",
    },
  ];

  const cardsgrow = [
    { text: "Digital Marketing Service 1", imgSrc: cardimage },
    { text: "Digital Marketing Service 2", imgSrc: topimage },
    { text: "Digital Marketing Service 3", imgSrc: topimage },
    { text: "Digital Marketing Service 4", imgSrc: topimage },
  ];

  return (
    <>
      <Layout>
        <div style={{ marginTop: "2%", marginBottom: "3%" }}>
          <div className="container-dgmmainbody">
            <div className="text-content">
              <h1>
                Integrated Digital Marketing <h1>Services</h1>
              </h1>

              <p>
                Digital marketing refers to the use of various digital channels
                and technologies to promote a product or service, engage with
                customers, and drive business growth. Digital marketing channels
                can include websites, social media platforms, search engines,
                email, mobile apps, and more.
              </p>
            </div>
            <div className="image-content">
              <img src={topimage} alt="Digital Marketing Illustration" />
            </div>
          </div>
        </div>
        {/* agency section */}
        <div className="agency-mainbody">
          <h3 className="agency-headingone">
            Why Digital Marketing <h3>Agency</h3>
          </h3>{" "}
          <div>
            <hr
              style={{
                width: "10px",
                height: "100px",
                backgroundColor: "#000",
              }}
              className="dgm-line"
            />
          </div>
          <p className="agency-paraone">
            Digital marketing services have become essential in today's world
            because they provide businesses with the tools and strategies needed
            to reach and engage with their target audience. Digital marketing
            allows businesses to connect with potential customers through
            various channels, such as search engines, social media, email, and
            websites, among others.
          </p>
        </div>
        <div>
          <h2
            style={{
              textAlign: "center",
              marginTop: "8%",
              marginBottom: "3%",
              fontSize: "2rem",
            }}
          >
            What We Offer
          </h2>
          <div className="card-grid-container">
            {cards.map((card, index) => (
              <div key={index} className="dgm-card">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    top: "-47px",
                  }}
                >
                  <h3
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#38B6FF",
                    }}
                  >
                    {index + 1}
                  </h3>
                </div>
                <h3 className="dgm-cardh3">{card.title}</h3>
                <p className="dgm-cardp">{card.description}</p>
                <button className="dgm-button">Know More</button>
              </div>
            ))}
          </div>
        </div>

        {/* faq question section */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            height: "100vh",
            // backgroundColor: "#007bff",
          }}
        >
          <Grid
            item
            xs={11}
            sm={9}
            md={7}
            lg={8}
            style={{
              backgroundColor: "#fff",
              padding: "50px",
              borderRadius: "5px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              height: "max-content",
            }}
          >
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Frequently Asked Questions
            </Typography>

            {/* First Accordion */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>What is digital marketing?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Digital marketing refers to promoting products or services
                  through digital channels such as search engines, social media,
                  email, mobile apps, and websites.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Second Accordion */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>
                  What are the different types of digital marketing?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The different types include SEO, SEM, social media marketing,
                  email marketing, and more.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Third Accordion */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography>
                  What are the key metrics for measuring digital marketing
                  success?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The key metrics for measuring digital marketing success
                  include website traffic, conversion rates, click-through
                  rates, engagement rates and ROI (return on investment).
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Fourth Accordion */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                <Typography>
                  How do you develop a digital marketing strategy?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Developing a digital marketing strategy involves identifying
                  your target audience, setting specific goals, selecting
                  appropriate digital channels, creating a content plan and
                  measuring and adjusting your strategy based on results.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
       
        <div className="experience-main-body">
          <h3 className="experience-headingone">
            Get the benefit of our professional and technical
            <br /> experience, ask for a quote now
          </h3>
          <h3 className="experience-select">
            Select the services you are looking for
          </h3>
          <div style={{display:"flex",alignItems:"center",}}>


          <div className="container-dgmsearch">
            <div className="select-option">
              <input type="checkbox" className="tick" />
              <label className="label-text">Search Engine Optimization</label>
            </div>
            <div className="select-option">
              <input type="checkbox" className="tick" />
              <label className="label-text">Social Media management</label>
            </div>
            <div className="select-option">
              <input type="checkbox" className="tick" />
              <label className="label-text">Product Marketing</label>
            </div>
            <div className="select-option">
              <input type="checkbox" className="tick" />
              <label className="label-text">Performance marketing</label>
            </div>
            <div className="select-option">
              <input type="checkbox" className="tick" />
              <label className="label-text">Other</label>
            </div>
          </div>
          {/* second */}
          <div className="container-dgmsearch">
            <div className="select-option">
              <input type="checkbox" className="tick" />
              <label className="label-text">Search Engine Optimization</label>
            </div>
            <div className="select-option">
              <input type="checkbox" className="tick" />
              <label className="label-text">Social Media management</label>
            </div>
          
          </div>
          </div>
          {/*  */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{
                display: "flex",
                padding: "11px 20px",
                textAlign: "end",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius:"5px",
              }}
            >
              Next
            </button>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default DigitalMarketing;
